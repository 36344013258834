import { useCallback, useEffect, useMemo } from 'react';
import { cx } from '@emotion/css';
import { TitleBar } from '@procurenetworks/procure-component-library';
import Loader from 'app/components/Loader';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import User from 'app/i18n/User';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import currentUser from 'app/modules/auth/utils/currentUser';
import useForgotPassword from 'app/modules/auth/views/ForgotPassword/hooks/useForgotPassword';
import {
  ForgotPasswordInput,
  ForgotPasswordResponse,
} from 'app/modules/auth/views/ForgotPassword/types';
import { titlebarStyles } from 'app/modules/components/EntityManager/styles';
import { CreateUserInput } from 'app/types/schema';
import Box from 'app/ui-components/Box';

import AddUserForm from '../AddUser/components/AddUserForm';
import { useUpdateUserMutation } from './graphql/mutations/generated/updateUser';
import { useEditUserQuery } from './graphql/queries/generated/editUser';

type Props = RouteComponentProps & { userId: string };

function EditUser(props: Props) {
  const { userId, history } = props;
  const [{ data }, onEditUser] = useEditUserQuery({
    variables: { userId },
    requestPolicy: 'network-only',
  });
  const currentUserTenantId = currentUser.tenantId();
  useEffect(() => {
    if (data && currentUserTenantId !== data.user.tenantId) history.push(routes.Users());
  }, [data, currentUserTenantId]);
  const [{ fetching: disabled }, onUpdateUser] = useUpdateUserMutation();
  const { fetching, execute } = useForgotPassword();

  const user = useMemo(() => {
    const userNode = data?.user;

    if (userNode) {
      const { id, ...rest } = userNode;
      return rest;
    }

    return null;
  }, [data?.user, data]);

  const onSubmit = useCallback(
    (input: CreateUserInput) => {
      const { tenantId, ...rest } = input;
      onUpdateUser({ input: { ...rest, userId } }).then((response) => {
        if (response.data?.updateUser.user?.id) {
          SnackbarService.show({ message: 'This user has been successfully updated.' });
          history.replace(routes.Users());
          onEditUser();
        }
        window.location.reload();
      });
    },
    [onUpdateUser, userId, onEditUser],
  );

  const onForgotPassword = async (props: ForgotPasswordInput) => {
    const result: ForgotPasswordResponse = await execute(props);
    if (result.success) {
      SnackbarService.show({
        message: 'Password reset email sent successfully.',
      });
    } else {
      SnackbarService.showError({
        message:
          result.errorCode || result.message || 'Some error occurred. Please try again later.',
      });
    }
  };

  const onCancel = useCallback(() => {
    history.replace(routes.Users());
  }, [history]);

  if (!user) {
    return <Loader />;
  }

  return (
    <Box className="bg-white px-24 pt-0 pb-16">
      <Box className={cx('max-w-[768px] space-y-40', titlebarStyles)}>
        <TitleBar title={User.EditUser} hasTopDivider />
        <AddUserForm
          isEditMode
          defaultValues={user}
          disableResetButton={fetching}
          disabled={disabled}
          onCancel={onCancel}
          onResetPassword={onForgotPassword}
          onSubmit={onSubmit}
        />
      </Box>
    </Box>
  );
}

export default withRouter(EditUser);
